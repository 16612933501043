import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Row from '@emcasa/ui-dom/components/Row';
import Col from './colstyle';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "grid-system"
    }}>{`Grid System`}</h1>
    <p>{`Our grid system is based on `}<a parentName="p" {...{
        "href": "https://github.com/rebassjs/grid"
      }}>{`rebassjs/grid`}</a>{`. The `}<inlineCode parentName="p">{`Row`}</inlineCode>{` component is a wrapper for `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` and `}<inlineCode parentName="p">{`Col`}</inlineCode>{` is a wrapper for `}<inlineCode parentName="p">{`Box`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Fragment mdxType="Fragment">
  <Col mdxType="Col">Full width column (12/12)</Col>
  <Row mdxType="Row">
    <Col width={1 / 12} mdxType="Col">1</Col>
    <Col width={11 / 12} mdxType="Col">11</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={2 / 12} mdxType="Col">2</Col>
    <Col width={10 / 12} mdxType="Col">10</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={3 / 12} mdxType="Col">3</Col>
    <Col width={9 / 12} mdxType="Col">9</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={4 / 12} mdxType="Col">4</Col>
    <Col width={8 / 12} mdxType="Col">8</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={5 / 12} mdxType="Col">5</Col>
    <Col width={7 / 12} mdxType="Col">7</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={6 / 12} mdxType="Col">6</Col>
    <Col width={6 / 12} mdxType="Col">6</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={7 / 12} mdxType="Col">7</Col>
    <Col width={5 / 12} mdxType="Col">5</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={8 / 12} mdxType="Col">8</Col>
    <Col width={4 / 12} mdxType="Col">4</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={9 / 12} mdxType="Col">9</Col>
    <Col width={3 / 12} mdxType="Col">3</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={10 / 12} mdxType="Col">10</Col>
    <Col width={2 / 12} mdxType="Col">2</Col>
  </Row>
  <Row mdxType="Row">
    <Col width={11 / 12} mdxType="Col">11</Col>
    <Col width={1 / 12} mdxType="Col">1</Col>
  </Row>
    </Fragment>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      